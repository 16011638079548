import React, { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { getRelevantAdsForPatient } from "src/apicalls/getRelatedAdsForPatient"
import { GlobalContext } from "src/App"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import { Advertisement as AdvertisementType, User } from "src/types"
import urls from "../../common/urls"
import PatientBlock from "./PatientBlock/PatientBlock"
import styles from "./UserHomePage.module.scss"

const config = {
  numberOfAdsPerPatient: 2,
  numberOfAds: 4,
}

const UserHomePage: React.FC = () => {
  const context = useContext(GlobalContext)
  const user = context.user as User
  const [advertisements, setAdvertisements] = React.useState<
    Map<number, AdvertisementType[] | undefined>
  >(() => new Map())
  const patientsToDisplay = user.patients.filter((patient) => !patient.profile.archived)

  useEffect(() => {
    const getAdsForPatients = async () => {
      const localAds = new Map<number, AdvertisementType[] | undefined>()
      const promises = patientsToDisplay.map(async (patient) => {
        let ads = (await getRelevantAdsForPatient(context.userToken, patient.id)).status.message
        if (ads?.length > 0) {
          localAds.set(patient.id, ads)
        }
      })
      await Promise.all(promises)
      setAdvertisements(new Map(localAds))
    }
    getAdsForPatients()
  }, []) // intentionally avoid rerequesting ads when user.patients changes

  const navigate = useNavigate()
  console.log(advertisements)
  let adsAlreadyDisplayed = new Set<number>()
  const patientBlocks = patientsToDisplay.map((patient, index) => {
    const activeTickets = user.tickets.filter((ticket) => {
      return patient.id === ticket.patientID && !ticket.archived
    })

    const numberOfAdsToDisplay = Math.min(
      config.numberOfAdsPerPatient,
      config.numberOfAds - adsAlreadyDisplayed.size,
    )
    let adsToDisplay: AdvertisementType[] | undefined = undefined
    if (numberOfAdsToDisplay) {
      const ads = advertisements.get(patient.id)
      adsToDisplay = ads
        ?.filter((ad: AdvertisementType) => !adsAlreadyDisplayed.has(ad.id))
        .slice(0, numberOfAdsToDisplay)
      if (adsToDisplay?.length) {
        adsAlreadyDisplayed = new Set([...adsAlreadyDisplayed, ...adsToDisplay.map((ad) => ad.id)])
      }
    }
    return (
      <PatientBlock
        key={index}
        ads={adsToDisplay}
        patient={patient}
        activeTickets={activeTickets}
      />
    )
  })

  if (patientBlocks?.length > 0) {
    return (
      <>
        <div className={styles.container}>{patientBlocks}</div>
      </>
    )
  }
  return (
    <>
      <GenericButton
        style="blue"
        action={() => navigate({ pathname: urls.userNewPatientPageUrl })}
        text="Új páciens felvétele"
        className={styles.button}
      />
      <p className={styles.text}>
        A fenti gombbal új páciensként fel kell vennie gyermekét vagy gyámsága alá tartozót. Ezt
        követően tud a betegséghez tartozó információkat megosztani orvossal vagy egyéb
        adminisztrációs igényeket intézni. A gyermek tünetei alapján tanácsokat is kap (orvosaink
        által írt, hitelesített), amíg az orvosa visszajelez.
      </p>
    </>
  )
}

export default UserHomePage
