import { ApiResponse } from "src/types"
import { APIBASEURL, headersBase } from "."

export const trackAdInteraction = async (
  userToken: string,
  ad_id: number,
  interaction_type: "view" | "hover" | "click",
  patient_id: number,
) => {
  const res = await fetch(APIBASEURL + `/ad/${ad_id}/interaction`, {
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      interaction_type,
      patient_id,
    }),
  })
  const result: ApiResponse = await res.json()
  return result
}
