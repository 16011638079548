import React, { useContext, useEffect } from "react"
import { trackAdInteraction } from "src/apicalls/trackAdInteraction"
import { GlobalContext } from "src/App"
import { Advertisement as AdType } from "src/types"
import styles from "./Advertisement.module.scss"

interface Props extends AdType {
  patientID: number
  className?: string
}

export const Advertisement = (props: Props) => {
  const mouseOverSentRef = React.useRef(false)
  const context = useContext(GlobalContext)
  const background = `url(${process.env.REACT_APP_API_URL}/media/advertisement?filename=${props.imageFilename})`

  const handleAdClick = () => {
    trackAdInteraction(context.userToken, props.id, "click", props.patientID)
    window.open(props.externalLink, "_blank")
  }

  const onHover = () => {
    if (mouseOverSentRef.current) return
    mouseOverSentRef.current = true
    trackAdInteraction(context.userToken, props.id, "hover", props.patientID)
  }

  useEffect(() => {
    trackAdInteraction(context.userToken, props.id, "view", props.patientID)
  }, [])

  return (
    <div
      onMouseOver={onHover}
      className={[styles.outerContainer, props.className || ""].join(" ")}
      onClick={handleAdClick}
    >
      <div
        style={{ "--background-image": background } as React.CSSProperties}
        className={styles.container}
      >
        <div className={styles.content}>
          <div className={styles.textContainer}>
            <h1>{props.title}</h1>
            <h2>{props.subtitle}</h2>
            <p>{props.description}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
