import React, { useContext, useEffect, useState } from "react"
import { getRelevantAdsForMultiplePatients } from "src/apicalls/getRelatedAdsForPatient"
import { GlobalContext } from "src/App"
import { Advertisement as AdvertisementComponent } from "src/common-components/Advertisement/Advertisement"
import Loader from "src/common-components/Loader/Loader"
import { Advertisement, drHomePages, Ticket } from "src/types"
import DrTicketMinified from "./DrTicketMinified/DrTicketMinified"
import styles from "./TicketOverviewPage.module.css"

const adConfig = {
  ticketsBetweenAds: 7,
  firstAdIndex: 1,
}

interface Props {
  tickets: Ticket[]
  loading?: boolean
}

const TicketOverviewPage: React.FC<Props> = ({ tickets, loading = false }) => {
  const { activePage, userToken } = useContext(GlobalContext)
  const [ads, setAds] = useState<Advertisement[]>([])

  const patientIds = tickets.map((ticket) => ticket.patientID)

  useEffect(() => {
    const getAds = async () => {
      const batches = []
      while (patientIds.length > 0) {
        batches.push(patientIds.splice(0, adConfig.ticketsBetweenAds))
      }
      const ads = batches.map(async (batch) => {
        return (await getRelevantAdsForMultiplePatients(userToken, batch)).status.message[0]
      })
      setAds(await Promise.all(ads))
    }
    getAds()
  }, [])

  const components: JSX.Element[] = []
  let currentBatch = 0
  tickets.forEach((ticket, index) => {
    if (
      index - adConfig.firstAdIndex >= 0 &&
      (index - adConfig.firstAdIndex) % adConfig.ticketsBetweenAds === 0 &&
      ads[currentBatch]
    ) {
      components.push(
        <AdvertisementComponent {...ads[currentBatch]} patientID={ticket.patientID} />,
      )
      currentBatch++
    }
    components.push(<DrTicketMinified key={index} ticket={ticket} />)
  })

  if (loading) return <Loader />
  return activePage === drHomePages.closed && !tickets[0] ? (
    <div className={styles.noTicketMsg}>A kijelölt időszakban nem található ügy</div>
  ) : (
    <div className={styles.container}>{components}</div>
  )
}

export default TicketOverviewPage
