import { useContext, useEffect, useState } from "react"
import { getRelevantAdsForPatient } from "src/apicalls/getRelatedAdsForPatient"
import { GlobalContext } from "src/App"
import { Advertisement, Ticket } from "src/types"

export const useRelatedAdForTicket = (ticket?: Ticket | null, isDoctor: boolean = false) => {
  const { userToken } = useContext(GlobalContext)
  const [ad, setAd] = useState<Advertisement | null>(null)

  useEffect(() => {
    const getAds = async () => {
      if (!ticket?.patientID) return
      const res = await getRelevantAdsForPatient(userToken, ticket?.patientID)
      if (res.status.result === "ok") {
        setAd(res.status.message[0])
      }
    }
    getAds()
  }, [ticket?.patientID])

  return ad
}
