import { Advertisement, ApiResponse } from "src/types"
import { APIBASEURL, headersBase } from "."

export const getRelevantAdsForPatient = async (
  userToken: string,
  patientId: number,
  isDoctor: boolean = false,
) => {
  const res = await fetch(APIBASEURL + `/ads/patient/${patientId}?is_doctor=${isDoctor}`, {
    method: "GET",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "content-type": "application/json",
    },
  })
  const result: ApiResponse<Advertisement[]> = await res.json()
  return result
}

export const getRelevantAdsForMultiplePatients = async (
  userToken: string,
  patientIds: number[],
) => {
  const res = await fetch(APIBASEURL + `/ads/patients/${patientIds.join(",")}`, {
    method: "GET",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "content-type": "application/json",
    },
  })
  const result: ApiResponse<Advertisement[]> = await res.json()
  return result
}
