import { createTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"
import React, { useEffect, useRef, useState } from "react"
import "react-big-calendar/lib/css/react-big-calendar.css"
import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"
import Popup from "src/common-components/Popup/Popup"
import { getUserData } from "./apicalls"
import { getNotifications } from "./apicalls/getNotifications"
import styles from "./App.module.css"
import Loader from "./common-components/Loader/Loader"
import Mobile from "./common-components/Mobile/Mobile"
import Tooltip from "./common-components/Tooltip/Tooltip"
import { dummyDrToken, dummyUserToken } from "./config"
import { useIsMobileSize } from "./common/hooks/useIsMobileSize"
import { usePopupWhenCalled } from "./common/hooks/usePopupWhenCalled"
import { getCookie } from "./common/utilityFunctions"
import { config } from "./config"
import DrApp from "./DrApp"
import { Doctor, drHomePages, ErrorMessageOptions, Notification, User } from "./types"
import UserApp from "./UserApp"
import { getRelevantAdsForPatient } from "./apicalls/getRelatedAdsForPatient"
import { trackAdInteraction } from "./apicalls/trackAdInteraction"

export let setErrorMessage: React.Dispatch<React.SetStateAction<ErrorMessageOptions>> | null = null

export const GlobalContext = React.createContext<{
  userToken: string
  user: User
  doctor: Doctor
  setShouldUpdateUserDataFromServer: React.Dispatch<React.SetStateAction<boolean>>
  notifications: { seen: Notification[]; unseen: Notification[] }
  setNotifications: React.Dispatch<
    React.SetStateAction<{
      seen: Notification[]
      unseen: Notification[]
    }>
  >
  activePage: drHomePages
  setActivePage: React.Dispatch<React.SetStateAction<drHomePages>>
  updating: boolean
}>({} as any)

export const overlayChildrenContext = React.createContext<{
  children: JSX.Element | null
  setChildren: React.Dispatch<React.SetStateAction<JSX.Element | null>>
}>({} as any)

function App() {
  const isMobile = useIsMobileSize()
  const [notifications, setNotifications] = useState<{
    seen: Notification[]
    unseen: Notification[]
  }>({ seen: [], unseen: [] })
  const [updating, setUpdating] = useState(true)
  const intialLoadRef = useRef<boolean>(true)
  const notificationsRef = useRef(notifications)
  const [overlayChildren, setOverlayChildren] = useState<JSX.Element | null>(null)

  const [shouldUpdateUserDataFromServer, setShouldUpdateUserDataFromServer] = useState(false)
  const [userToken, setUserToken] = useState(
    config.built ? getCookie() : localStorage.getItem("token") || dummyUserToken,
  )
  const [user, setUser] = useState<User>({} as User)
  const [doctor, setDoctor] = useState<Doctor>({} as Doctor)
  const [activeVideoCall, setActiveVideoCall] = usePopupWhenCalled(user)

  // activePage on doctorHomePage
  const [activePage, setActivePage] = useState(drHomePages.inProgress)

  // Error popup visibility + referenceCode
  const [errorMessage, setErrorMessageInternal] = useState<ErrorMessageOptions>({ visible: false })

  useEffect(() => {
    setShouldUpdateUserDataFromServer(true)
  }, [notifications.unseen.length])

  useEffect(() => {
    // Delete stored scroll position on page reload
    localStorage.removeItem("ov-scroll")
    const setNotificationsToState = async () => {
      if (document.hidden) {
        return
      }

      const res = await getNotifications(userToken)
      if (res !== "error" && res.unseen.length !== notificationsRef.current.unseen.length) {
        setNotifications(res)
        notificationsRef.current = res
      }
    }
    const getInitialData = async () => {
      if (!userToken) window.location.replace(config.LOGIN_PAGE_URL)
      setUserToken(userToken)
      setNotificationsToState()
      const res = await getUserData(userToken)
      if (!res) {
        if (config.REDIRECT_ON_INVALID_USERTOKEN) window.location.replace(config.LOGIN_PAGE_URL)
        return
      }
      if (res.status?.result === "error") {
      } else {
        if (res.status?.message.doctor) {
          setDoctor(res.status.message as Doctor)
        } else {
          setUser(res.status?.message as User)
        }
        setUpdating(false)
        intialLoadRef.current = false
      }
    }
    getInitialData()
    const notificationUpdateInterval = setInterval(
      setNotificationsToState,
      config.INFO_UPDATE_INTERVAL,
    )
    setErrorMessage = setErrorMessageInternal
    return () => clearInterval(notificationUpdateInterval)
  }, [])

  useEffect(() => {
    const updateUserData = async () => {
      if (shouldUpdateUserDataFromServer) {
        setUpdating(true)
        const res = await getUserData(userToken)
        if (!res) {
          if (config.REDIRECT_ON_INVALID_USERTOKEN) window.location.replace(config.LOGIN_PAGE_URL) // TODO: error msg
          return
        }
        if (res.status?.message.doctor) {
          setDoctor(res.status.message as Doctor)
          setUser({} as User)
        } else {
          const user = res.status?.message as User
          getRelevantAdsForPatient(userToken, user.patients[0].id)
          trackAdInteraction(userToken, 1, "click", user.patients[0].id)
          setUser(res.status?.message as User)
          setDoctor({} as Doctor)
        }
        setShouldUpdateUserDataFromServer(false)
        setUpdating(false)
      }
    }
    updateUserData()
  }, [shouldUpdateUserDataFromServer])

  const switcheroo = () => {
    if (userToken === dummyDrToken) {
      setUserToken(dummyUserToken)
      localStorage.setItem("token", dummyUserToken)
    } else {
      setUserToken(dummyDrToken)
      localStorage.setItem("token", dummyDrToken)
    }
    setShouldUpdateUserDataFromServer(true)
  }

  if (isMobile && !config.ACCESSIBLE_FROM_MOBILE) return <Mobile />

  if (updating && intialLoadRef.current) {
    return (
      <div className="loaderContainer">
        <Loader />
      </div>
    )
  }

  const theme = createTheme({
    palette: {
      primary: { main: "#47cdff" },
    },
    typography: {
      fontFamily: ["Nunito Sans", "Sans Serif"].join(", "),
    },
  })

  return (
    <>
      {config.built ? null : (
        <button onClick={switcheroo} className={styles.switcheroo}>
          switcheroo
        </button>
      )}
      <ThemeProvider theme={theme}>
        <GlobalContext.Provider
          value={{
            userToken,
            user,
            doctor,
            setShouldUpdateUserDataFromServer,
            notifications,
            setNotifications,
            activePage,
            setActivePage,
            updating,
          }}
        >
          <overlayChildrenContext.Provider
            value={{ children: overlayChildren, setChildren: setOverlayChildren }}
          >
            {overlayChildren ? <div className={styles.overlay}>{overlayChildren}</div> : null}
            {Object.keys(user).length > 0 ? (
              <UserApp activeVideoCall={activeVideoCall} />
            ) : (
              <DrApp />
            )}

            {errorMessage.visible ? (
              <Popup
                title="Szerverhiba"
                onClose={() => {
                  errorMessage.dismissAction?.()
                  setErrorMessageInternal({ visible: false })
                }}
                confirmButton={{
                  title: "Bezárás",
                }}
                panelStyle={{ maxWidth: "30rem" }}
                fullscreenContainer
              >
                <div className={styles.popupContent}>
                  <p>
                    <strong>Váratlan hiba történt a szerverrel való kommunikáció során.</strong>
                  </p>
                  <p>
                    Az esetet technikai körülményeit anonim módon naplóztuk, hogy fejlesztőink
                    kiavíthassák a hibát. Önnek nincs további teendője.
                  </p>
                  {errorMessage.referenceCode ? (
                    <Tooltip tooltipText="Kattintson a kód másolásához">
                      <p
                        onClick={() =>
                          navigator.clipboard.writeText(
                            errorMessage.referenceCode ? errorMessage.referenceCode : "",
                          )
                        }
                        className={styles.errorReferenceText}
                      >
                        Esemény azonosító:
                        <span className={styles.errorReferenceCode}>
                          {errorMessage.referenceCode ? errorMessage.referenceCode : ""}
                        </span>
                      </p>
                    </Tooltip>
                  ) : null}
                </div>
              </Popup>
            ) : null}
          </overlayChildrenContext.Provider>
        </GlobalContext.Provider>
      </ThemeProvider>
    </>
  )
}

export default App
