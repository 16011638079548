/****************Patient related types*********************/

import { OtherTaskData } from "./user-components/NewTicketPage/OtherTaskCard/OtherTaskCard"
import { ticketStartData } from "./user-components/UserHomePage/PatientBlock/NewTicketStart"

export interface PatientFormData extends CreatePatientData {
  hasDrugAllergy: boolean | ""
  beenToHospital: boolean | ""
  hasPersistentDisease: boolean | ""
}

export type CreatePatientDataFirstCard = {
  firstName: string
  lastName: string
  foreName: string
  birthName: string
  mothersName: string
  birthYear: string
  birthMonth: string
  birthDay: string
}

export type CreatePatientDataThirdCard = {
  [key: string]: any
  height?: string
  weight: string
  GP?: { name: string; id: number | null }
  tajNum: string
  sex: Sex | ""
}

export type CreatePatientDataFourthCard = {
  [key: string]: any
  drugAllergy?: string[]
  hasDrugAllergy: boolean | ""
}

export type CreatePatientDataFifthCard = {
  [key: string]: any
  beenToHospital: boolean | ""
  hospitalVisits: HospitalVisit[]
}

export interface HospitalVisit {
  year: string
  month: string
  diagnose: string
  media: { id: number; name: string } | null
  mediaAtCreation: MediaAtCreation[]
}

export type CreatePatientDataSixthCard = {
  [key: string]: any
  illnesses: illnessForm[]
  hasPersistentDisease: boolean | ""
}

export type CreatePatientData = CreatePatientDataFirstCard & {
  address: Address
} & CreatePatientDataThirdCard &
  CreatePatientDataFourthCard &
  CreatePatientDataFifthCard &
  CreatePatientDataSixthCard

export type PatchPatientData = {
  patientID: number
  patientProfile: CreatePatientData
}

export type DeletePatientData = {
  patientID: number
}

export type illnessForm = {
  illnessName: string
  takesDrugs?: boolean
  drugs: DrugForm[]
}

export type DrugForm = {
  agentAmount: string
  agentUnit: "mg" | "μg" | "mg/5ml" | "mg/ml" | ""
  dose: string
  name: string
  media: { id: number; name: string } | null
  mediaAtCreation: MediaAtCreation[]
}

interface drugData extends DrugForm {
  archived?: true
}

interface illnessData extends illnessForm {
  archived?: true
  drugs: drugData[]
}

export interface PatientProfileData extends PatientFormData {
  username?: string
  archived?: boolean
  illnesses: illnessData[]
}

export type Patient = {
  id: number
  profile: PatientProfileData
}

export interface PatientDataOverviewLabelValuePairs {
  label: string
  value: string | undefined | JSX.Element[]
}

export type NewPatientHistoryType =
  | undefined
  | {
      patientID?: number
    }

export type Sex = "male" | "female"

/*****************Ticket related types************/

export type Task =
  | PrescriptionTaskData
  | SickNoteTaskData
  | DiagnosisTaskData
  | ReferralTaskData
  | SickPayTaskData
  | ConsultationTaskData
  | OtherTaskData

export interface TaskDataBase {
  state: null | "wip" | "ready" | "rejected"
  finishedAt: number | null
  taskID: number
  name: TaskTypes
}

export interface PrescriptionFormData {
  illnessIndex: string
  drugIndex: string
  amount: string
  drugName: string
  prescriptionReason: string
  additionalInfo: string
}

export type PrescriptionTaskData = {
  name: "prescription"
  prescriptionComment: string | null
  doctorData: {
    files: { id: number; name: string }[]
    comment: string | null
    receivable: boolean
    receivableOnline: boolean
    documentID?: string
  }
} & PrescriptionFormData &
  TaskDataBase

export type ReferralFormData = {
  referralTargetID: string
  referralTargetName: string
  referralTargetDepartment: string
}

export type ReferralTaskData = {
  name: "referral"
  doctorData: {
    files: { id: number; name: string }[]
    comment: string | null
    receivable: boolean
    receivableOnline: boolean
    documentID?: string
  }
} & TaskDataBase &
  ReferralFormData

interface DiagnosisSickNoteTaskData extends TaskDataBase {
  name: "diagnosis" | "sickNote"
  doctorData: {
    files: { id: number; name: string }[]
    comment: string | null
    therapy: string | null
    receivable: boolean
    receivableOnline: boolean
    diagnosis: string
    documentID?: string
  }
}

export interface DiagnosisTaskData extends DiagnosisSickNoteTaskData {
  name: "diagnosis"
  covid: boolean
}

export interface SickNoteTaskData extends DiagnosisSickNoteTaskData {
  name: "sickNote"
  startDate: number | null
  endDate: number | null
}

export type SickPayTaskData = TaskDataBase & {
  name: "sickPay"
  doctorData: {
    files: { id: number; name: string }[]
    comment: string | null
    receivable: boolean
    receivableOnline: boolean
    documentID?: string
  }
  startDate: number | null
  endDate: number | null
}

export interface PersonalConsultationTaskData extends TaskDataBase {
  name: "consultation"
  doctorData: { date: number | null; comment?: string | null }
  type: "personal"
}

export interface VideoConsultationTaskData extends TaskDataBase {
  name: "consultation"
  doctorData: { videoCallID: number | null; comment?: string | null }
  type: "video"
}

export type ConsultationTaskData = VideoConsultationTaskData | PersonalConsultationTaskData

export const TaskTypesArray = [
  "referral",
  "prescription",
  "diagnosis",
  "sickPay",
  "sickNote",
  "consultation",
  "other",
] as const
type TaskTypesTuple = typeof TaskTypesArray
export type TaskTypes = TaskTypesTuple[number]

export const TaskLabels: { [key in TaskTypes]: string } = {
  referral: "Beutaló",
  prescription: "Recept",
  diagnosis: "Diagnózis",
  sickPay: "Táppénz",
  sickNote: "Igazolás",
  consultation: "Konzultáció",
  other: "Egyéb",
}

export type CreateTicketApiData = {
  patientID: number
  ticketData: TicketData
  mediaArray?: Image[]
}

export type PatchTicketApiData = {
  ticketData?: Partial<TicketData>
  ticketID: number
  ready?: boolean
  archived?: boolean
  doctor?: { id?: number; name: Name }
}

export type PatchTicketTaskDatesApiData = {
  startDate: number | null
  endDate: number | null
}

export type Ticket = {
  userProfile?: UserProfile
  patientProfile?: PatientProfileData
  userID: number
  ticketID: number
  patientID: number
  doctor: { id: null | number; name: Name | null }
  ticketData: TicketData
  priority: boolean
  archived: boolean
  ready: boolean
}

export type ApiMedicDiagnosis = {
  Issue: {
    Accuracy: number
    ID: number
    Icd: string
    IcdName: string
    Name: string
    ProfName: string
    Ranking: number
  }
}

export type TicketAssignee = "doctor" | "assistant" | "parent"

export type TicketData = {
  createdAt?: number
  closedAt?: number
  targetDrID: number | null
  diagnosis?: ApiMedicDiagnosis[]
  assignee?: {
    assignee: TicketAssignee
    blocked: boolean
  }
  tasks: Task[]
  comment: string
  outpatientData: null | OutpatientFormData
  files: AssignedMedia[]
  symptoms: {
    symptomID: number
    symptomInterval: { start: number; end: number | null }
  }[]
}

export type OutpatientFormData = {
  status: string
  diagnosis: string
  therapy: string
  opinion: string
}

export type VideoCallRequest = {
  userID: number
  userName: Name
  doctorName: Name
  ticketID: number
  date: number | null
}

export type VideoCall = {
  id: number
  userID: number
  userName: Name
  doctorID: number
  doctorName: Name
  ticketID: number
  date: number
  offer: string
  answer: string
  hasVideoStream: boolean
  reverseRoles: boolean
}

export type VideoPageHistoryType =
  | {
      videoCallID: number
    }
  | undefined

export type TicketPageHistoryType =
  | {
      ticketID: number
    }
  | undefined

/**************User related types********************/

export interface UserApi {
  doctor: false
  userID: number
  tickets: Ticket[]
  userProfile: UserProfile
  patients: Patient[]
  videoCalls: VideoCall[]
}

export interface User extends UserApi {
  personalConsultations: PersonalConsultationNotificationData[]
}

export type UserProfileFirstCard = {
  firstName: string
  lastName: string
  foreName: string
  birthName: string
  mothersName: string | null
  tajNum: string | null
  job: string | null
  birthYear: string
  birthMonth: string
  birthDay: string
}

export type UserProfileSecondCard = {
  address: Address
  phone: string | null
}

export interface DoctorApi {
  doctor: true
  userID: number
  patients: null
  tickets: DoctorTickets
  userProfile: DoctorProfile
  videoCalls: VideoCall[]
  verified: boolean
}

export interface Doctor extends DoctorApi {
  personalConsultations: PersonalConsultationNotificationData[]
}

export interface PersonalConsultationNotificationData {
  date: number
  partnerName: Name
  ticketID: number
  finished: boolean
}

export type UserProfile = UserProfileFirstCard & UserProfileSecondCard & { email?: string }

export type DoctorProfileFirstCard = {
  birthYear: string
  birthMonth: string
  birthDay: string
  title: string
  lastName: string
  firstName: string
  foreName: string
  birthName: string
  antszLicenseNumber: string
}

export type DoctorProfileSecondCard = {
  zone: string
  officeAddress: Address
}

export type CompanyData = {
  name: string
  seat: string
  VATnumber: string
  companyNumber: string
  rep: string
  bankAccountNumber: string
}

export type DoctorProfileThirdCard = { company: CompanyData }

export type DoctorProfile = DoctorProfileFirstCard &
  DoctorProfileSecondCard &
  DoctorProfileThirdCard

export interface NotificationPreferences {
  lowPrio: boolean
  highPrio: boolean
}

/**********Image and other media related types***********/

export type ImageMeta = {
  createdAt: number | null
  name: string
  description?: string
  format: string
  ticketID: number | null
}

export type Image = {
  meta: ImageMeta
  data: string
}

export interface MediaAtCreation {
  file: File
  meta: ImageMeta
}

export interface AssignedMedia {
  id: number
  name: string
}

/*********Other types related to data storing************/

export type Address = {
  [key: string]: string
  country: string
  region: string
  city: string
  zipCode: string
  street: string
  streetNum: string
}

export type Name = {
  lastName: string
  firstName: string
  foreName: string | null
  title?: string | null
}

/************Api related types *******************/

export type ApiResponse<T = any> = {
  status: {
    message: T
    result: "ok" | "error"
  }
}

export type Message = {
  senderID: number
  content: string
  timestamp: number
}

export type Notification = {
  id: number
  seen: boolean
  sender: Name
  subject:
    | "new-message"
    | "new-targeted-ticket"
    | "new-ticket-subscriber"
    | "ticket-closed"
    | "new-task"
    | "task-updated"
    | "videocall-started"
    | "videocall-date-added"
    | "videocall-date-changed"
  ticketID: number
  timestamp: number
}

export interface NotificationsObj {
  seen: Notification[]
  unseen: Notification[]
}

export type doctorListApiResponse = {
  status: {
    result: string
    message: {
      userID: number
      firstName: string
      lastName: string
      foreName: string
    }[]
  }
}

/***************LogBook related types******************/

export type LogBookBaseEntryTitles =
  | "Láz"
  | "Fejfájás"
  | "Kiütés"
  | "Hasfájás"
  | "Hányás"
  | "Gyógyszerezés"
  | "Széklet"
  | "Vizelet"
  | "Orrszívás"

export interface LogBookEntryFormValues {
  description: string | null
  medication: {
    drugIndex: number | null | "Egyéb"
    drugName: string | null
    agentUnit: DrugForm["agentUnit"] | null
    agentAmount: string
  } | null
}

export interface LogBookEntry extends LogBookEntryFormValues {
  id?: number
  title: string | null
}

export interface LogBookEntryClient extends LogBookEntry {
  start: Date | null
  end: Date | null
}

export interface LogBookEntryServer extends LogBookEntry {
  start: number | null
  end: number | null
}

export type LogBookPageHistoryType =
  | {
      patient: Patient
    }
  | undefined

/**************Article related types *****************/

export interface Article {
  title: string
  keywords: string[]
  description: string
  image: string
  sponsored: boolean
  url: string
}

export type ArticlePageHistory =
  | {
      ticketID?: number
      apiMedicDiagnosis?: ApiMedicDiagnosis[]
      ticketData: TicketData
    }
  | undefined

/***************Other types******************/

export enum drHomePages {
  inProgress,
  incoming,
  open,
  closed,
}

export type DoctorTickets = {
  inProgress: Ticket[]
  incoming: Ticket[]
  open: Ticket[]
}

export type SelectedFilters = { [key in TaskTypes]: boolean }

export type ErrorMessageOptions = {
  visible: boolean
  referenceCode?: string
  dismissAction?: () => void
}

export const overlayTypes = ["msg-overlay", "noti-overlay", "consultations-overlay"] as const
export type OverlayType = (typeof overlayTypes)[number]

export interface Advertisement {
  imageFilename: string
  title: string
  subtitle: string
  description: string
  externalLink: string
  id: number
}
